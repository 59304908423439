import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

function App() {
  const [data, setData] = useState([]);
  const skiAreas = [
    { name: "Laajavuori", id: "1", tracks: [{ id: 123, name: "" }] },
    { name: "Tikkakoski" },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch(
        "https://jyvaskyla.fluentprogress.fi/outdoors/api/public/venue/list"
      );
      const data = await result.json();

      setData(
        data.features.filter((row: any) => {
          return row.properties.type === "Skitrack";
        })
      );
    };
    fetchData();
  }, []);
  console.log(data);
  return (
    <div className="App">
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Aluelue</InputLabel>
          <Select labelId="area-select" id="area-select" value={1} label="Alue">
            {skiAreas.map((area: any) => (
              <MenuItem value={area.id}>{area.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Viimeksi huollettu</TableCell>
              <TableCell align="right">Paikka</TableCell>
              <TableCell align="right">Latu</TableCell>
              <TableCell align="right">Ladun tila</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right">{row.properties.id}</TableCell>

                <TableCell component="th" scope="row">
                  {row.properties.maintenance.latest}
                </TableCell>
                <TableCell align="right">{row.properties.name}</TableCell>
                <TableCell align="right">
                  {row.properties.name.split(",")[0]}
                </TableCell>
                <TableCell align="right">{row.properties.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default App;
